@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Have a Heart One';
  src: local('Have a Heart One'), 
    url('./fonts/HaveHeartOne.woff') format('woff'),
    url('./fonts/HaveHeartOne.woff2') format('woff2'),
    url('./fonts/HaveHeartOne.ttf') format('truetype');
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
:root {
	--mainColor: #ff6699;
	--secondaryColor: #f2f2f2;
  --textColor: #333333;
  --lightBackground: #ffffff;
  --darkBackground: #0d0d0d;
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
} 

#root {
  white-space: pre-line;
}

#light{
  background-color: var(--lightBackground);
}

#dark{
  background-color: var(--darkBackground);
}

html{
  scroll-behavior:smooth;
  font-size: 62.5%;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
} 
body { 
  min-height: 100% 
}

html, p, body, code{
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: var(--textColor);
}

#dark html, #dark p, #dark body, #dark code{
  color: var(--secondaryColor);
}
h1, h2, h3, h4, h5, h6{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: var(--textColor);
}
#dark h1, #dark h2, #dark h3, #dark h4, #dark h5, #dark h6{
  color: var(--secondaryColor);
}
h1{font-size: 3.052rem;}
h2{font-size: 2.441rem;}
h3{font-size: 1.953rem;}
h4{font-size: 1.563rem;font-weight: 300;}
h5{font-size: 1.558rem;}
h6{font-size: 1.25rem;}

p{
  margin: 5px 0;
  line-height: 1.5rem;
}

img{
  width: 100%;
}

a:any-link {
  text-decoration-line: none;
  cursor: pointer !important;
}

:focus-visible {
  outline: #ff6699 auto 1px;
}

section{
  display: block;
  position: relative;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: block;
  position: relative;
 }

button {
  border: none;
	cursor: pointer !important;
}
button:hover {
  transition: 0.5s;
}

.btn{
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  background-color: var(--mainColor);
  color: #ffffff;
  border: 1px solid var(--mainColor);
  padding: 12px 25px;
  margin: 5px 0;
  border-radius: 3px;
  text-decoration-line: none;
  cursor: pointer !important;
  transition: 1s;
  fill: #fff;
  font-size: 1rem;
}
.btn:hover{
  background-color: transparent;
  color: var(--mainColor);
  transition: 1s;
  box-shadow: 0 0 8px 0 #ff6699 inset, 0 0 15px 2px #ff6699;
}

.btn-secondary{
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  background-color: transparent;
  color: var(--mainColor);
  border: 1px solid var(--mainColor);
  padding: 12px 25px;
  margin: 5px 0;
  border-radius: 3px;
  text-decoration-line: none;
  cursor: pointer !important;
  transition: 0.5s;
  fill: var(--mainColor);
}
.btn-secondary:hover{
  transition: 0.5s;
  box-shadow: 0 0 8px 0 #ff6699 inset, 0 0 10px 2px #ff6699;
}

.row{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.text-center{text-align: center !important;}
.text-left{text-align: left !important;}
.text-right{text-align: right !important;}

span[aria-label="Loading Spinner"]{
  position: absolute !important;
  top: 25% !important;
  left: 50% !important;
}

.title-block{
  text-align: center;
  position: relative;
  margin-bottom: 90px;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
h3.section-title{
  text-align: center;
  text-transform: uppercase;
  color: var(--textColor);
  z-index: 2 !important;  
  margin-bottom: -60px;
  position: relative;
}
#dark h3.section-title{
  color: var(--secondaryColor);
}
.section-subtitle{
  color: #f2f2f2;
  text-shadow: 4px 4px 8px #fff;
  font-family: 'Have a Heart One', Sans-serif;
  font-size: 9rem;
  font-weight: 400;
  text-align: center;
  z-index: 0 !important;
  transform: rotate(-8grad);
  line-height: 100px;
}
#dark .section-subtitle{
  color: var(--textColor);
  text-shadow: none;
}

.mode-switch{
  margin-left: 30px;
  position: fixed !important;
  bottom: 70px;
  z-index: 999;
transform: rotate(100grad);
}
.mode-switch .react-switch-bg{
  background: rgb(218,218,218);
  background: linear-gradient(0deg, rgba(218,218,218,1) 0%, rgba(242,242,242,1) 100%) !important;
}
.react-switch-handle{
  background-color: var(--textColor) !important;
}
#dark .mode-switch .react-switch-bg{
  background: rgb(51,51,51,1);
  background: linear-gradient(0deg, rgba(29,29,29,1) 0%, rgba(51,51,51,1) 100%) !important;
}
#dark .react-switch-handle{
  background-color: var(--secondaryColor) !important;
}
.react-switch-bg svg.bi.bi-sun {
  margin-top: 5px;
  margin-left: 8px !important;
}
.react-switch-bg svg.bi.bi-moon-stars-fill{
  margin-top: 7px;
  margin-left: 8px !important;
  transform: rotate(200grad);
}

/* Responsive */
.pc-only{
  display:block;
}
.ipad-pc-only{
  display:block;
}
.mobile-only{
  display:none;
}

@media only screen and (max-width: 1024px) {
  .pc-only{
    display:none;
  }
  span[aria-label="Loading Spinner"]{
    left: 45% !important;
  }
}

@media only screen and (max-width: 767px) {
  .row{flex-direction: column;}
  .ipad-pc-only{
      display: none !important;
  }
  .mobile-only{
      display: block;
  }
  .title-block{
    margin-bottom: 60px;
  }
  h3.section-title {
    max-width: 300px;
    margin: 0 auto -60px auto;
  }
  .mode-switch{
    margin-left: 20px;
  }
  span[aria-label="Loading Spinner"]{
    left: 42% !important;
  }
  main{
    overflow-x: hidden;
  }
  .section-subtitle.two-lines{
    margin-top: -100px;
  }
}